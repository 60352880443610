.btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 20px;
	border: 1px solid var(--bgContentSecondary);
	border-radius: 8px;

	font: 700 15px/24px Inter;
	/* color: var(--bgContentMain); */
}
.btn-small {
	height: 44px;
	border-radius: 12px;
	border: none;
}
.btn-xsmall {
	height: 32px;
	border-radius: 12px;
	border: none;
	font-size: 0.875rem;
}
.btn-outlined {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--bgContentSecondary);
	background-color: #ffffff;
	border-radius: 12px;
	padding: 8px 16px;
	display: inline-flex;

	font: 600 14px/24px Inter;
	color: var(--weak);
}
.btn-primary {
	background: var(--secondary) !important;
	border-color: var(--secondary) !important;
	color: var(--bgContentMain);
}
.btn-secondary {
	background: var(--bgContentSecondary);
	color: #000000;
}
.btn-primary:disabled {
	background-color: var(--gray) !important;
	border-color: var(--gray) !important;
	cursor: not-allowed;
	opacity: 0.7;
}

.btn-back {
	font: 600 12px/12px Inter;
	color: var(--weak);
	display: flex;
	align-items: center;
	background: transparent;
	border: none;
	margin-bottom: 28px;
}

.btn-back span {
	font: 600 12px/12px Inter;
	text-transform: uppercase;
	margin-left: 10px;
}

.btn-sidebar {
	width: 100%;
	height: 54px;
	padding: 15px;
	border: 1px solid #ffffff;
	border-radius: 10px;
	background-color: white;
	font-size: 15px;
	line-height: 18px;
	color: black;
	margin-bottom: 5px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.btn-sidebar span {
	font: 500 15px/18px Inter;
	font-weight: 500;
	margin-left: 30px;
	color: var(--weak);
}
.btn-sidebar svg {
	width: 30px;
	height: 100%;
	fill: var(--weak);
}
.btn-sidebar.selected,
.btn-sidebar:hover {
	background-color: #f7f7f7;
	color: var(--secondary);
}
.btn-sidebar.selected span {
	color: var(--secondary);
}
.btn-sidebar.selected svg {
	fill: var(--secondary);
}

.btn-icon {
	margin-right: 0.5rem;
	padding: 0.5rem;
	border-radius: 0.25rem;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.btn-icon:disabled {
	cursor: not-allowed;
	opacity: 0.7;
}

.btn-select {
	z-index: 1047;
}
.btn-select.s1 {
	z-index: 1048;
}
.btn-select.s2 {
	z-index: 1046;
}
.btn-select > .select__menu {
	z-index: 1049;
}
.btn-select > .select__menu > .select__menu-list > .select__option {
	color: #000;
}
.btn-select > .select__menu > .select__menu-list > .select__option.select__option--is-selected {
	background-color: var(--secondary);
	color: white;
}

.btn-select > .select__control {
	box-shadow: none;
}
.btn-select > .select__control:hover {
	border-color: #024f88;
}
.btn-select > .select__control > .select__value-container {
	padding: 2px 12px;
}
.btn-select.error > .select__control {
	border: 2px solid var(--required)!important;
}
.btn-select.min-h-auto {
	position: unset;
}
.btn-select.min-h-auto .select__menu {
	width: auto;
}
.btn-select.min-h-auto > .select__control {
	min-height: 40px;
}

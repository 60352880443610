.section-container {
	width: 100%;
	height: calc(100vh - 78px);
	position: relative;
	/* padding: 32px 40px 40px 40px; */
	padding: 32px 16px 24px 16px;
	background-color: var(--bgDisable);
	overflow-y: auto;
}
.section-container.inner {
	padding: 32px 16px 24px 16px;
	height: calc(100vh - 78px);
	overflow-y: hidden;
}
.section-container.child {
	width: 100%;
	height: calc(100vh - 254px);
	position: relative;
	padding: 0 24px 24px 24px;
	background-color: var(--bgContentMain);
	border-radius: 8px;
	overflow: auto;
}
.section-container.dashboard {
	width: 100%;
	min-height: calc(100vh - 78px);
	height: 100%;
	position: relative;
	padding: 32px 16px 24px 16px;
	background-color: var(--bgDisable);
}
.section-title {
	font: 600 40px/48px Inter;
	color: var(--neutrals);
	margin-bottom: 24px;
}

.section-sidebar {
	width: 280px;
	min-height: calc(100vh - 78px);
	background-color: white;
	padding: 24px 16px;
	font: normal 14px/24px Inter;
	color: #d1d1d1;
	transition: width 0.3s;
	flex-shrink: 0;
}

.title-sidebar {
	position: relative;
	font: 700 14px/17px Inter;
	color: #92929d;
	margin-bottom: 15px;
	text-transform: uppercase;
}
.sidebar_disable {
	position: absolute;
	top: 0;
	left: 0;
	width: 280px;
	min-height: calc(100vh - 78px);
	background-color: rgba(211, 211, 211, 0.5);
	z-index: 1058;
}

.section-container-settings {
	position: relative;
	height: calc(100vh - 78px);
	width: calc(100vw - 280px);
	padding: 32px 16px 24px 16px;
	background-color: #f4f4f4;
	/* margin-top: 78px; */
	overflow: hidden;
}
.section-card {
	width: 100%;
	height: calc(100vh - 78px - 130px);
	position: relative;
	padding: 16px;
	background-color: var(--bgContentMain);
	border-radius: 8px;
	overflow: auto;
}
.section-card > .vertical {
	width: 100%;
	display: flex;
	position: relative;
	overflow: auto;
}
.section-warning {
	position: absolute;
	top: 0;

	border: none;
	border-radius: 5px;
	background: var(--bgWarning);
	/* color: #f27474; */
	padding: 0.5rem 0.75rem;
	color: var(--textWarning);
}
.section-warning .title {
	font-weight: bold;
}
.section-warning.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-16px);
		transform: translateY(-16px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-16px);
		transform: translateY(-16px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.section-error {
	width: 100%;
	height: 100svh;
	display: flex;
	flex-direction: column;
	margin: auto;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

#tableFont div.rdt_TableCol:nth-child(2),
#tableFont div.rdt_TableCell:nth-child(2),
#tableBackground div.rdt_TableCol:nth-child(2),
#tableBackground div.rdt_TableCell:nth-child(2),
#tableRoadNumber div.rdt_TableCol:nth-child(2),
#tableRoadNumber div.rdt_TableCell:nth-child(2) {
	min-width: 102px;
	max-width: 102px;
}
#tableFont div.rdt_TableCol:nth-child(3),
#tableFont div.rdt_TableCell:nth-child(3),
#tableBackground div.rdt_TableCol:nth-child(3),
#tableBackground div.rdt_TableCell:nth-child(3),
#tableRoadNumber div.rdt_TableCol:nth-child(3),
#tableRoadNumber div.rdt_TableCell:nth-child(3) {
	max-width: 200px;
}
#tableFont div.rdt_TableCol:nth-child(4),
#tableFont div.rdt_TableCell:nth-child(4),
#tableBackground div.rdt_TableCol:nth-child(4),
#tableBackground div.rdt_TableCell:nth-child(4),
#tableRoadNumber div.rdt_TableCol:nth-child(4),
#tableRoadNumber div.rdt_TableCell:nth-child(4) {
	max-width: 250px;
}

#tableFont div.rdt_TableCol:nth-child(5),
#tableFont div.rdt_TableCell:nth-child(5) {
	min-width: unset;
	max-width: 100%;
}

#tableBackground div.rdt_TableCol:nth-child(5),
#tableBackground div.rdt_TableCell:nth-child(5),
#tableRoadNumber div.rdt_TableCol:nth-child(5),
#tableRoadNumber div.rdt_TableCell:nth-child(5) {
	min-width: unset;
	max-width: 250px;
}

#tableBackground div.rdt_TableCol:nth-child(6),
#tableBackground div.rdt_TableCell:nth-child(6),
#tableRoadNumber div.rdt_TableCol:nth-child(6),
#tableRoadNumber div.rdt_TableCell:nth-child(6) {
	min-width: unset;
	max-width: 100%;
}

#tableFont div.rdt_TableCol:nth-child(6),
#tableFont div.rdt_TableCell:nth-child(6),
#tableFont div.rdt_TableCol:nth-child(7),
#tableFont div.rdt_TableCell:nth-child(7),
#tableBackground div.rdt_TableCol:nth-child(7),
#tableBackground div.rdt_TableCell:nth-child(7),
#tableBackground div.rdt_TableCol:nth-child(8),
#tableBackground div.rdt_TableCell:nth-child(8),
#tableRoadNumber div.rdt_TableCol:nth-child(7),
#tableRoadNumber div.rdt_TableCell:nth-child(7),
#tableRoadNumber div.rdt_TableCol:nth-child(8),
#tableRoadNumber div.rdt_TableCell:nth-child(8) {
	min-width: 96px;
	max-width: 96px;
	text-align: center;
	display: flex;
	justify-content: center;
}

.rdt_TableHead {
  border-bottom: 1px solid var(--bgContentSecondary);
}
.rdt_TableHeadRow input[type='checkbox'],
.rdt_TableCell input[type='checkbox'],
.ckCustom {
	/* width: 19px;
  height: 19px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1a1818;
  border-radius: 6px; */

	-webkit-appearance: none;
	appearance: none;
	background-color: var(--bgContentMain);
	margin: 0;

	font: inherit;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 2px solid rgba(111, 118, 126, 0.4);
	border-radius: 6px;
	transform: translateY(-0.075em);
  transition: 120ms transform ease-in-out;

	display: grid;
	place-content: center;
}
.rdt_TableHeadRow input[type='checkbox']:checked,
.rdt_TableCell input[type='checkbox']:checked,
.ckCustom:checked {
	background-color: var(--secondary);
  background-image: url('../../assets/icons/ic-checklist.png');
  background-repeat: no-repeat;
  background-position: center;
}
.ckCustom.disabled {
	cursor: not-allowed;
}
.ckCustom.inner {
	width: 18px;
	height: 18px;
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
}
/* .rdt_TableHeadRow input[type='checkbox']::before,
.rdt_TableCell input[type='checkbox']::before {
	content: '';
	width: 12px;
	height: 9px;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	background-color: var(--bgContentMain);

	transform-origin: bottom left;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.rdt_TableHeadRow input[type='checkbox']:checked::before,
.rdt_TableCell input[type='checkbox']:checked::before {
	transform: scale(1);
} */

/* .rdt_TableHeadRow input[type='checkbox']:focus,
.rdt_TableCell input[type='checkbox']:focus {
	box-shadow: 0 0 0 0.25rem rgb(26 24 24 /25%);

	outline: max(2px, 0.15em) solid currentColor;
	outline-offset: max(2px, 0.15em);
} */

/* .rdt_TableHeadRow input:checked[type='checkbox'],
.rdt_TableCell input:checked[type='checkbox'] {
	background-image: url('../SettingsPage/assets/checklist.svg');
} */

.rdt_TableHeadRow input[type='checkbox']:indeterminate {
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #1a1818;
	background-image: url('../SettingsPage/assets/line.svg');
}

#pagination-previous-page,
#pagination-next-page {
	color: #92929d;
	border: 1px solid #e2e2ea;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#pagination-first-page,
#pagination-last-page {
	display: none;
}

#pagination-previous-page {
	margin-right: 0.5rem;
}

.rdt_Pagination span {
	display: none;
}

.rdt_Pagination div:first-of-type {
	margin-right: 2rem;
}

.rdt_Pagination div:first-of-type select {
	border: 1px solid #e2e2ea;
	border-radius: 8px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rdt_Pagination div:first-of-type svg {
	top: 8px;
}

.tb {
	height: 72px;
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.5rem 0.75rem;
	justify-content: space-between;
}

.tb::after {
	content: '';
	width: 100%;
	height: 1px;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	background: var(--bgContentSecondary);
}

.tbTC {
	position: relative;
	width: auto;
	height: 40px;
	display: flex;
	align-items: center;
	font: 600 15px/24px Inter;
	background-color: #fcfcfc;
	color: var(--weak);
	padding: 8px 16px;
	margin-right: 8px;
	border-radius: 8px;
	cursor: pointer;
}
.tbTC:hover {
	color: var(--secondary);
}
.tbTCA {
	background-color: var(--bgContentSecondary);
	color: #000000;
}
.tab-title {
    display: flex;
	align-items: center;
	overflow-x: auto;
	flex-wrap: nowrap;
}
.tab-title > div {
	flex: 0 0 auto;
}
.tab.vertical {
	position: relative;
	width: 180px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 24px;
}
.tabV-item {
	width: 100%;
}

:root {
	/* Color */
	--white: #ffffff;
	--primary: #024f88;
	--secondary: #0051ba;
	--weak: #6f767e;
	--gray: #92929d;
	--bgDisable: #f4f4f4;
	--bgContentMain: #fcfcfc;
	--bgContentSecondary: #efefef;
	--labelColor: #33383f;
	--required: #d93934;
	--neutral: #272b30;
	--textTable: #1a1d1f;
	--bgWarning: #F8F4C4;
	--titleWarning: #97722F;
	--textWarning: #A98A54;
}

body {
	font-family: system-ui, -apple-system, 'Inter', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

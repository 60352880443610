@font-face {
  font-family: 'inter-thin';
  src: url('./assets/fonts/InterThin.ttf') format('truetype');
}

@font-face {
  font-family: 'inter';
  src: local('inter'), url('./assets/fonts/InterRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'inter-medium';
  src: local('inter-medium'), url('./assets/fonts/InterMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'semi-bold';
  src: local('semi-bold'), url('./assets/fonts/InterSemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'inter-bold';
  src: local('inter-bold'), url('./assets/fonts/InterBold.ttf') format('truetype');
}

@import url("./styles/root.css");
@import url("./styles/button.css");
@import url("./styles/form.css");
@import url("./styles/modal.css");
@import url("./styles/section.css");
@import url("./styles/table.css");
@import url("./styles/tab.css");
@import url("./components/DataTable/DataTable.css");

.page-loader {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

#bds-side-drawer::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background: white;
}
#bds-menu-option::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background: white;
}

.m-project-list::-webkit-scrollbar {
	width: 5px;
}
.m-project-list::-webkit-scrollbar-track {
	background: #f4f4f4;
}
.m-project-list::-webkit-scrollbar-thumb {
	background: rgba(111, 118, 126, .15);
}

#side-panel::-webkit-scrollbar {
	width: 0px;
}
#side-panel::-webkit-scrollbar-track {
	background: transparent;
}
#side-panel::-webkit-scrollbar-thumb {
	background: transparent;
}

/* old */

.remove-default-a {
  text-decoration: unset;
}

.remove-default-a:hover {
  color: initial;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background: white;
}

*::-webkit-scrollbar-thumb {
  border-radius: 13px;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 10px;
  border: 3px solid transparent;
  color: #d1d1d1;
}

a {
  text-decoration: none;
}

.dropdown-menu.show {
  position: fixed !important;
  font: 600 14px/14px Inter;
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

body > iframe {
  z-index: -1 !important;
}
.buttonCk .checkbox {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: pointer;
	z-index: 3;
}
.buttonCk .knobs,
.buttonCk .layer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.buttonCk {
	position: relative;
	top: 50%;
	width: 74px;
	height: 36px;
	margin: -20px auto 0 auto;
	overflow: hidden;
}

.buttonCk.r,
.buttonCk.r .layer {
	border-radius: 100px;
}

.buttonCk .knobs:before {
	content: 'NO';
	position: absolute;
	top: 6px;
	left: 8px;
	width: 24px;
	height: 24px;
	color: #fff;
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	line-height: 1;
	padding: 8px 4px;
	background-color: #fff;
	border-radius: 50%;
	transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.buttonCk .checkbox:checked + .knobs:before {
	content: 'YES';
	left: 42px;
	background-color: #024f88;
	color: #024f88;
}

.buttonCk .checkbox:checked ~ .layer {
	background-color: #f4f4f4;
}

.buttonCk .knobs,
.buttonCk .knobs:before,
.buttonCk .layer {
	transition: 0.3s ease all;
}

.buttonCk .knobs {
	z-index: 2;
}

.buttonCk  .layer {
	width: 100%;
	background-color: #f4f4f4;
	transition: 0.3s ease all;
	z-index: 1;
}

.dot_warning {
	-webkit-animation: 1s ease infinite blink;
	-moz-animation: 1s ease infinite blink;
	-ms-animation: 1s ease infinite blink;
	animation: 1s ease infinite blink;
}
@keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-moz-keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-webkit-keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}


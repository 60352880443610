.modal-container.md-project > .modal-dialog {
	width: 650px;
	max-width: 650px;
}
.modal-content {
	border-radius: 15px;
}
.modal_title {
	display: flex;
	justify-content: space-between;
	padding: 24px;
	height: 68px;
	position: relative;
	font: 600 14px/14px Inter;
	color: var(--weak);
}
.modal_body {
	padding: 0 24px 0 24px;
}
.modal_btn_close {
	width: 20px;
	height: 20px;
	background-size: 12px 12px;
	padding: 0;
}
.modal_btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 48px;
	background: #0051ba;
	border-radius: 8px;
	padding: 12px 20px;
	margin: 0;
	font: 700 14px/24px Inter;
	color: #fcfcfc;
	border: 0;
}
.modal_btn.btn-gray {
	background: var(--bgContentSecondary);
	color: #000000;
}
.modal_btn.btn-yellow {
	background: #fab80a;
	color: #000000;
}

.custom-context-menu {
	position: fixed;
	z-index: 1010;
	background: var(--bgContentSecondary);
	border-radius: 4px;
	padding: 4px 0px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.context-menu-button {
	border: none;
	padding: .25rem 0.75rem;
}
.context-menu-button:not(:last-child) {
	border-bottom: 1px solid rgba(146, 146, 157, 0.5);
}

.context-menu-button:hover {
	background-color: rgba(111, 118, 126, 0.3);
}
.context-menu-input {
	height: 32px;
	border: 1px solid rgba(146, 146, 157, 0.5);
	border-radius: 0;
}

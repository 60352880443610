.form-input,
.form-selects {
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	position: relative;
}
.form-input > label {
	font: 600 14px/24px inter;
	color: var(--labelColor);
	margin-bottom: 8px;
}
.form-input > input {
	height: 48px;
	padding: 12px;
	background-color: var(--bgContentMain);
	border: 2px solid var(--bgContentSecondary);
	border-radius: 8px;
}
.form-input > input[type='checkbox'] {
	width: 24px;
	height: 18px;
	margin-right: 8px;
}
.form-input.checkbox {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: flex-end;
}
.form-input.checkbox > label {
	margin-bottom: 0;
}
.form-input > textarea {
	padding: 12px;
	background-color: var(--bgContentMain);
	border: 2px solid var(--bgContentSecondary);
	border-radius: 8px;
}
.form-input > input:focus-visible,
.form-input > textarea:focus-visible,
.form-selects > input:focus-visible,
.form-selects > textarea:focus-visible {
	outline: none;
}
.form-input .input-required,
.form-selects .input-required,
.label-required {
	color: var(--required);
}
.form-input .form-input-error,
.form-selects .form-input-error,
.form-input-error {
	border: 2px solid var(--required) !important;
}
.form-input .input-required.error {
	position: absolute;
	bottom: -18px;
	font-size: 14px;
}

.formInputLabel {
	font: 600 14px/24px Inter;
	color: #33383f;
	margin-bottom: 8px;
}
.formInputLabel.sm {
	font: 400 12px/14px Inter;
}
.formInput {
	width: -webkit-fill-available;
	height: 48px;
	padding: 12px;
	background-color: #fcfcfc;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 8px;
	font: 600 14px/24px Inter;
	color: #1a1d1f;
}
.formInput.textarea {
	min-height: 48px;
	height: 100%;
}
.formSelect {
	width: 100%;
	height: 48px;
	padding: 12px 36px 12px 12px;
	background-color: #fcfcfc;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 8px;
	font: 600 14px/24px Inter;
	color: #1a1d1f;

	/* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"); */
	/* background-size: 18px 18px; */
}
.formInput:focus,
.formInput:focus-visible {
	border: 2px solid var(--bgContentSecondary);
	box-shadow: none;
	outline: none;
}
.formInput:disabled {
	cursor: not-allowed;
}
.formSelect:focus,
.formSelect:focus-visible {
	box-shadow: none;
	outline: none;
}
.formSelect-creatable > div {
	min-height: 48px;
	background-color: #fcfcfc;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 8px;
	font: 600 14px/24px Inter;
	color: #1a1d1f;
}
.formSelect.date {
	padding: 12px;
}
.w-color input {
	width: 78px;
	margin-right: 16px;
	text-align: center;
}
.f-placeholder {
	display: inline-block;
	min-height: 2em;
	vertical-align: middle;
	cursor: wait;
	/* background-color: currentColor; */
	opacity: 0.5;

	/* animation: placeholder-glow 2s ease-in-out infinite; */
}
@keyframes placeholder-glow {
	50% {
		opacity: 0.2;
	}
}
.formSelect.default {
	padding: 0;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 8px;
}
.formSelect.default > div:nth-child(3n) {
	border-color: var(--bgContentSecondary);
	box-shadow: none;
	padding: 0;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 8px;
}

.formSelect.default div:nth-child(4n) {
	z-index: 10000;
}
.formCheckbox-container {
	display: table-cell;
	position: relative;
	width: 74px;
	height: 40px;
	box-sizing: border-box;
}
.formCheckbox-container span {
	position: absolute;
	z-index: 1;
	top: 7px;
	right: 14px;
}
.formCheckbox-container span.isChecked {
	left: 14px;
}
.formCheckbox .ck {
	position: relative;
	top: 0;
	width: 102px;
	height: 36px;
	margin: 0 auto;
	overflow: hidden;
	background-color: var(--bgContentSecondary);
}

.formCheckbox .r,
.formCheckbox .r .layer {
	border-radius: 100px;
}

.formCheckbox .checkbox {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: pointer;
	z-index: 3;
}
.formCheckbox .knobs {
	z-index: 2;
}

.formCheckbox .layer {
	width: 100%;
	background-color: red;
	transition: 0.3s ease all;
	z-index: 1;
}

.formCheckbox .ck .knobs:before {
	content: 'NO';
	position: absolute;
	top: 6px;
	left: 8px;
	width: 24px;
	height: 24px;
	color: var(--weak);
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	line-height: 1;
	padding: 8px 4px;
	background-color: var(--weak);
	border-radius: 50%;
	transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.formCheckbox .ck .checkbox:checked + .knobs:before {
	content: 'YES';
	left: 68px;
	background-color: #024f88;
	color: #024f88;
}

.formCheckbox .ck .checkbox:checked ~ .layer {
	background-color: #f4f4f4;
}

.formCheckbox .ck .knobs,
.formCheckbox .ck .knobs:before,
.formCheckbox .ck .layer {
	transition: 0.3s ease all;
}
